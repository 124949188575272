@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");

html {
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.apexcharts-tooltip span {
  color: #000000;
}

.otp-input > div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.otp-input > div > input {
  width: 50px !important;
  height: 50px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .otp-input > div > input {
    width: 50px !important;
    height: 50px;
  }
}

@media (max-width: 900px) {
  .otp-input > div > input {
    width: 40px !important;
    height: 40px;
  }

  .logoMain {
    height: 50px !important;
  }
}

@media (max-width: 600px) {
  .otp-input > div > input {
    width: 40px !important;
    height: 40px;
  }
}
.css-1grmulm-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1grmulm-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1grmulm-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.css-1grmulm-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
  background-color: transparent !important;
}

.rendered {
  outline: none !important;
}

.apexcharts-legend {
  justify-content: center !important;
}

.swiper,
.swiper-pagination {
  position: inherit !important;
}

.swiper-pagination-bullet-active {
  background: #ee825f !important;
}
.swiper-pagination-bullet {
  background: #ee825f !important;
}
.mySwiper2 {
  position: relative !important;
}

.mySwiper1 .swiper-slide {
  cursor: pointer;
}
